import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { Lead } from '../models/lead.model';
import { LeadComment } from '../models/lead-comment.model';
import * as config from '../shared/configs/global.config';

@Injectable({
    providedIn: 'root',
})
export class PartnerService {
    base_url: string = config.base_url;
    partnerURL = this.base_url+"/api/partners";
    partners: any[] = [];

    constructor(private http: HttpClient) {}

    getSelectPartners(search:any): Observable<any> {

        return this.http.post(this.partnerURL+'/lists', search)
            .pipe(
                map(data => {
                return data;
            }
        ));
    }

    getForms(): Observable<any> {

        return this.http.post(this.partnerURL+'/forms', [])
            .pipe(
                map(data => {
                return data;
            }
        ));
    }

    getPartners(): Observable<any> {
        return this.http.get(this.partnerURL)
            .pipe(
                map(data => {
                return data;
            }
        ));
    }

    getPartner(id:number) : Observable<any> {
        return this.http.get<any>(this.partnerURL+'/'+id);//<Lead>
    }

    getAureusUsers() : Observable<any> { 
        return this.http.get<any>(this.partnerURL+'/partners/aureususer');//<Lead>
    }
    
    createPartner(partner: any) {
        return this.http.post(this.partnerURL, partner);
    }
    deletePartners(vars:any) : Observable<any> { 
        return this.http.post(this.partnerURL+'/delete', vars);
    }
    updatePartner(vars: any) {
        return this.http.post(this.partnerURL+'/update2', vars);
    }

}
