//Bar chart Data

export var barChartSingle = [
  {
    "name": "Germany",
    "value": 894
  },
  {
    "name": "USA",
    "value": 500
  },
  {
    "name": "France",
    "value": 720
  },
  {
    "name": "Australia",
    "value": 650
  }
];

export var barChartmulti = [
  {
    "name": "01-2018",
    "series": [
      {
        "name": "",
        "value": 7300
      },
      {
        "name": "",
        "value": 8940
      }
    ]
  },

  {
    "name": "02-2018",
    "series": [
      {
        "name": "",
        "value": 7870
      },
      {
        "name": "",
        "value": 8270
      }
    ]
  },

  {
    "name": "03-2018",
    "series": [
      {
        "name": "",
        "value": 5000
      },
      {
        "name": "",
        "value": 5800
      }
    ]
  },
  {
    "name": "04-2018",
    "series": [
      {
        "name": "",
        "value": 6000
      },
      {
        "name": "",
        "value": 6500
      }
    ]
  },
  {
    "name": "05-2018",
    "series": [
      {
        "name": "",
        "value": 6000
      },
      {
        "name": "",
        "value": 6500
      }
    ]
  },
  {
    "name": "06-2018",
    "series": [
      {
        "name": "",
        "value": 6000
      },
      {
        "name": "",
        "value": 6500
      }
    ]
  },
  {
    "name": "07-2018",
    "series": [
      {
        "name": "",
        "value": 6000
      },
      {
        "name": "",
        "value": 6500
      }
    ]
  },
  {
    "name": "08-2018",
    "series": [
      {
        "name": "",
        "value": 6900
      },
      {
        "name": "",
        "value": 11500
      }
    ]
  },
  {
    "name": "09-2018",
    "series": [
      {
        "name": "",
        "value": 6000
      },
      {
        "name": "",
        "value": 6500
      }
    ]
  },
  {
    "name": "10-2018",
    "series": [
      {
        "name": "",
        "value": 6000
      },
      {
        "name": "",
        "value": 6500
      }
    ]
  },
  {
    "name": "11-2018",
    "series": [
      {
        "name": "",
        "value": 7000
      },
      {
        "name": "",
        "value": 8750
      }
    ]
  },
  {
    "name": "12-2018",
    "series": [
      {
        "name": "",
        "value": 9500
      },
      {
        "name": "",
        "value": 11150
      }
    ]
  }
];



