import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as config from '../shared/configs/global.config';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  base_url: string = config.base_url;
  www_url_api: string = config.www_url_api;
  offerURL = this.base_url+"/api/offers";

  constructor(private http: HttpClient) {}

  getOffers() : Observable<any> {
    return this.http.get<any>(this.offerURL);
  }

  getOffer(id) : Observable<any> {
    return this.http.get<any>(this.offerURL+'/'+id);
  }


}
