import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as config from '../shared/configs/global.config';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  base_url: string = config.base_url;
  constructor(private httpClient: HttpClient) { }

  sendFile(formData: any) {

    let baseUrl = this.base_url+'/api' 
    let url = baseUrl + '/logo'
    return this.httpClient.post(url, formData)
  }

  sendAvatar(formData: any) {

    let baseUrl = this.base_url+'/api' 
    let url = baseUrl + '/avatar'
    return this.httpClient.post(url, formData)
  }

  sendInvoiceFile(formData: any) {

    let baseUrl = this.base_url+'/api' 
    let url = baseUrl + '/summary/invoice'

    return this.httpClient.post(url, formData)
  }
}
